import React from 'react'

const HairGuide = () => {
  return (
    <div className="background-colour-beige-2">
      <div className="container py-4 text-center">
        <h2>Hair Length Guide</h2>
        <p>Short Hair: Jawline</p>
        <p>Medium Hair: Shoulders</p>
        <p>Long Hair: Below Shoulders, Above Bra Strap</p>
        <p>Extra Long Hair: Below Bra Strap</p>
        <br></br>
        <hr />
        <br></br>
        <h3>Let's take a look...</h3>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 pb-5">
          <div className="col">
            <a href="#cuts" className="links service-links">
              Cut
            </a>
          </div>
          <div className="col">
            <a href="#colors" className="links service-links">
              Colour
            </a>
          </div>
          <div className="col">
            <a href="#blowdrys" className="links service-links">
              Blowdry
            </a>
          </div>
          <div className="col">
            <a href="#treatments" className="links service-links">
              Treatment
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HairGuide
