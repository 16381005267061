import React from 'react'
import Layout from '../layout/Layout'
import BlowDry from './components/services/BlowDry'
import Colour from './components/services/Colour'
import FreeConsultationCta from './components/services/FreeConsultationCta'
import HairGuide from './components/services/HairGuide'
import StyleCutBlowDry from './components/services/StyleCutBlowDry'
import Treatment from './components/services/Treatment'
import Footer from './components/shared/Footer'
import NavBar from './components/shared/NavBar'

const ServicesPage = () => {
  return (
    <Layout>
      <NavBar />
      <div className="page-header-background-image page-header-services-background-image">
        <div className="container py-5 text-center">
          <h1 className="p-5">What We Offer</h1>
        </div>
      </div>
      <div className="mustard-divider"></div>
      <HairGuide />
      <StyleCutBlowDry />
      <BlowDry />
      <Colour />
      <Treatment />
      <FreeConsultationCta />
      <Footer />
    </Layout>
  )
}

export default ServicesPage
